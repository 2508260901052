import { makeAutoObservable } from 'mobx'
import { toast } from 'react-toastify'
import api from '../../api'

class StreamStore {
  streams = []
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
  }
  sorter = {
    field: 'changed',
    order: 'descend',
  }
  filter = {
    filterStream: 'all',
  }
  state = 'pending' // "pending", "done" or "error"

  editingStream = null

  usages = {
    state: 'pending',
    id: null, // stream id
    type: 'component',
    items: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
    sorter: {},
    filter: {},
    setPagination: this.setUsagesPagination,
  }

  previewState = 'pending'
  previewList = []
  previewPage = 0
  maxPreviewPage = 1
  MAX_PRODUCTS_IN_PREVIEW = 10

  isDirty = false

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  reset() {
    this.pagination = {
      current: 1,
      pageSize: 10,
      total: 0,
    }
  }

  setPagination(pagination) {
    this.pagination = pagination
    this.fetchStreams()
  }

  setDirty(dirty) {
    this.isDirty = dirty
  }

  setSorter(sorter) {
    this.reset()

    this.sorter = sorter
    this.fetchStreams()
  }

  setFilter(filter) {
    this.reset()

    this.filter = filter
    this.fetchStreams()
  }

  getById(id) {
    return this.streams.find((s) => s.id == id)
  }

  *fetchStreams(init) {
    if (init) {
      this.filter = {
        filterStream: 'all',
      }
    }

    this.state = 'pending'
    try {
      const { data, total } = yield api.streams.getAll({
        pagination: this.pagination,
        filter: this.filter,
        sorter: this.sorter,
      })

      this.streams = data
      this.pagination.total = total
      this.state = 'done'
    } catch (error) {
      toast.error('Something went wrong loading the stream listing.')
      this.state = 'error'
    }
  }

  *fetchAllStreams() {
    this.state = 'pending'
    try {
      const { data } = yield api.streams.getAll({
        pagination: {
          pageSize: 9999,
        },
        sorter: {
          field: 'id',
        },
      })

      this.streams = data
      this.state = 'done'
    } catch (error) {
      toast.error('Something went wrong loading the stream listing.')
      this.state = 'error'
    }
  }

  *fetchById(id) {
    this.isDirty = false
    this.state = 'pending'
    try {
      const res = yield api.streams.get(id)
      const data = res.data
      this.editingStream = data
      this.state = 'done'
      return data
    } catch (error) {
      toast.error('Something went wrong when fetching stream detail.')
      this.state = 'error'
    }
  }

  /**
   * Example: Used in <StreamSelect> component
   */
  *fetchStreamsByTitle(filterString) {
    try {
      const { data } = yield api.streams.getAll({
        pagination: { current: 1, pageSize: 50 },
        filter: {
          filter: filterString,
        },
        sorter: { field: 'title', order: 'asc' },
      })

      return data
    } catch (error) {
      toast.error('Something went wrong loading the stream listing.')
    }
  }

  *fetchStreamInspector(options) {
    try {
      return yield api.streams.getProductsById(options)
    } catch (error) {
      return { items: [], total: 0 }
    }
  }

  *fetchStreamProducts(options) {
    const { page, language, config, group } = options
    this.previewState = 'pending'

    if (!config || Object.keys(config).length === 0) {
      this.previewList = []
      this.maxPreviewPage = 1
      this.previewPage = 0
      this.previewState = 'done'
    }
    try {
      const { items, total } = yield api.streams.getProductsPreview({
        language,
        config,
        count: this.MAX_PRODUCTS_IN_PREVIEW,
        offset: (page - 1) * this.MAX_PRODUCTS_IN_PREVIEW,
        group,
      })
      const previewList = items.map((item) => {
        return {
          id: item.id,
          fields: item,
        }
      })
      this.previewList = previewList
      this.previewPage = 0
      this.maxPreviewPage = Math.ceil(total / this.MAX_PRODUCTS_IN_PREVIEW)
      this.previewState = 'done'
      return { items, total }
    } catch (error) {
      return { items: [], total: 0 }
    }
  }

  *updateOrCreate(stream) {
    try {
      if (stream.id) {
        const { data } = yield api.streams.update(stream)
        return data
      } else {
        const { data } = yield api.streams.create(stream)
        return data
      }
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong...')
    }

    this.fetchStreams()
  }

  *delete(ids) {
    this.state = 'pending'
    try {
      yield api.streams.delete(ids)
      this.state = 'done'
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong...')
    }

    this.fetchStreams()
  }

  *copyStream(data) {
    try {
      yield api.streams.copy(data)

      this.fetchStreams()
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong...')
    }
  }

  get streamOptions() {
    return this.streams.map((item) => ({ value: item.id, label: item.title }))
  }

  resetStreamUsage(streamId, streamUsageType) {
    this.usages = {
      state: 'pending',
      id: streamId, // stream id
      type: streamUsageType,
      items: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      sorter: {},
      filter: {},
      setPagination: this.setUsagesPagination,
    }
  }
  openStreamDialog(streamId, streamUsageType) {
    this.resetStreamUsage(streamId, streamUsageType)
    this.fetchStreamsUsage(streamId, streamUsageType)
  }

  closeStreamDialog() {
    this.resetStreamUsage(null)
  }

  setUsagesPagination(pagination) {
    this.usages.pagination = pagination
    this.fetchStreamsUsage(this.usages.id, this.usages.type)
  }

  *fetchStreamsUsage(id, streamUsageType) {
    this.usages.state = 'pending'
    try {
      const { data, total } = yield api.streams.streamUsages({
        pagination: this.usages.pagination,
        id,
        type: streamUsageType,
      })

      this.usages.items = data
      this.usages.pagination.total = total || data.length
      this.usages.state = 'done'
    } catch (error) {
      toast.error('Something went wrong loading the stream listing.')
      this.usages.state = 'error'
    }
  }
}

export default new StreamStore()
