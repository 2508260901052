import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import cx from 'classnames'
import { useHistory, useParams } from 'react-router'

import PageTitle from '../../components/PageTitle'
import Switch from '../../components/Switch'
import Text from '../../components/Text'
import Select from '../../components/Select'
import NumberInput from '../../components/NumberInput'
import Icon from '../../components/Icon'
import LoadingScreen from '../../components/LoadingScreen'
import { useLeaveConfirm, t, createRedirectPath } from '../../utils'
import { formatDateWithoutSeconds } from '../../utils/formatDate'
import {
  ActionLayerStore,
  UIStore,
  BannerStore,
  EditorStore,
} from '../../stores'
import TimeControlModal from '../../components/Modal/TimeControlModal'
import ContentSection from './ContentSection'
import { routes } from '../../routing'
import PreviewSection from './PreviewSection'
import TextInput from '../../components/TextInput'
import DisplaySection from '../../components/DisplaySection'
import { set } from 'mobx'
import TrackingSection from '../../components/TrackingDetail'

import './style.styl'

function BannerForm() {
  const { id } = useParams()
  const {
    isDirty,
    state,
    showTimeControlModal,
    updatingBanner,
    bannerTypes,
    isFetchingDetail,
    onShowControlModal,
    onChangeBanner,
    setDirty,
    updateOrCreate,
    setPreviewPageUrl,
  } = BannerStore
  const { languages, currentLanguage, enterpriseConfiguration } = UIStore
  const history = useHistory()

  useEffect(() => {
    EditorStore.fetchPublishConfig()
    BannerStore.fetchBannerById(id, enterpriseConfiguration.defaultLanguage)
    BannerStore.fetchDeviceType()
    BannerStore.fetchDataTypes()
    BannerStore.getSampleDataForPreview()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (isDirty) {
      ActionLayerStore.openActionLayer({
        saveTitle: t('Save & back to list'),
        closeTitle: t('Abort & back to list'),
        onSaveWithContinue: handleSaveAndStay,
        onSave: handleSave,
        onClose: handleClose,
      })
    } else {
      ActionLayerStore.closeActionLayer()
    }

    return () => {
      ActionLayerStore.closeActionLayer()
    }
    // eslint-disable-next-line
  }, [isDirty])

  const prompt = useLeaveConfirm(isDirty)

  if (state === 'pending' || isFetchingDetail) return <LoadingScreen />

  async function handleSaveAndStay() {
    const result = await updateOrCreate()
    if (result && result.id) {
      history.replace(createRedirectPath(`/banner/${result.id}/`))
    }
  }

  function handleClose() {
    setDirty(false)
    onShowControlModal(false)
    history.push(createRedirectPath(routes.bannerList.path))
  }

  async function handleSave() {
    setDirty(false)
    const result = await updateOrCreate()
    if (result) {
      history.push(createRedirectPath(routes.bannerList.path))
    }
  }

  const getText = () => {
    if (
      !updatingBanner.activeTiming &&
      (!updatingBanner.personaIds || updatingBanner.personaIds?.length === 0) &&
      !updatingBanner.allPersona
    )
      return t('time-based or persona based visibility can be changed')
    const formattedFrom = updatingBanner.activeFrom
      ? `${t('from')} ${formatDateWithoutSeconds({
          dateString: updatingBanner.activeFrom,
        })}`
      : ''

    const formattedTo = updatingBanner.activeTo
      ? `${t('to')} ${formatDateWithoutSeconds({
          dateString: updatingBanner.activeTo,
        })}`
      : ''

    return (
      <div>
        {updatingBanner.allPersona && (
          <div>{t('Visible for All Personas')}</div>
        )}
        {updatingBanner.personaIds?.length > 0 &&
          updatingBanner.personaIds.map((id) => {
            const group =
              EditorStore.publishConfig.find((persona) => persona.id === id) ||
              {}
            return (
              <div key={group.identifier}>
                {t('Visible for')}{' '}
                {group.name?.[UIStore.currentLanguage] || group.name}
              </div>
            )
          })}
        {updatingBanner.activeTiming && (
          <span>{`${t('Scheduled')} ${formattedFrom} ${formattedTo}`}</span>
        )}
      </div>
    )
  }

  const onChangeDisplay = (value) => {
    setDirty(true)
    set(updatingBanner, {
      listType: value.type,
      identifier: value.identifier,
      searchAutosuggestBoth: value.searchType,
    })
  }

  return (
    <>
      <PageTitle
        prefix={
          updatingBanner.id ? t('You are editing') : t('You are creating')
        }
        metaInfo={
          updatingBanner.id && {
            timestamp: updatingBanner.changed?.date,
            timezone: updatingBanner.changed?.timezone,
            user: updatingBanner.user,
            id: updatingBanner.id,
          }
        }
        hiddenFields={['internal-title']}
      >
        {updatingBanner.id ? updatingBanner.name : t('New banner')}
      </PageTitle>
      <div className="content-wrapper banner-edit">
        <div className="banner-content">
          <div className="banner-edit__section">
            <div className="section__row">
              <Switch
                checked={updatingBanner.active || false}
                type="horizontal"
                title={t('Active')}
                onChange={(value) => onChangeBanner('active', value)}
              />
              <Text
                className={cx('banner-timecontrol--cta', {
                  active: updatingBanner.activeTiming,
                })}
                variant="book"
                element="p"
                onClick={() => onShowControlModal(!showTimeControlModal)}
              >
                <Icon symbol="clock" />
                {getText()}
              </Text>
              <TimeControlModal
                visible={showTimeControlModal}
                from={updatingBanner.activeFrom}
                to={updatingBanner.activeTo}
                onClose={() => onShowControlModal(false)}
                onChange={({ active, from, to, allPersona, personaIds }) => {
                  onChangeBanner('activeFrom', from)
                  onChangeBanner('activeTo', to)
                  onChangeBanner('activeTiming', active)
                  onChangeBanner('allPersona', allPersona)
                  onChangeBanner('personaIds', personaIds)
                }}
                language={currentLanguage}
                allPersona={updatingBanner.allPersona}
                personaIds={updatingBanner.personaIds || []}
              />
            </div>
            <TextInput
              label={t('Name')}
              value={updatingBanner.name}
              onChange={(e) => onChangeBanner('name', e.target.value)}
            />
            <div className="section__row">
              <Select
                title={t('Language')}
                options={languages.map((lang) => {
                  return { value: lang, label: lang }
                })}
                value={updatingBanner.language}
                onChange={(value) => onChangeBanner('language', value)}
              />
              <NumberInput
                label={t('Banner position')}
                min={1}
                value={updatingBanner.position}
                onChange={(value) => onChangeBanner('position', value)}
              />
              <Select
                title={t('Type')}
                options={bannerTypes
                  .filter((type) => type.label)
                  .map((type) => ({
                    value: type.id,
                    label: type.label,
                  }))}
                value={updatingBanner.type}
                onChange={(value) => onChangeBanner('type', value)}
              />
            </div>
          </div>
          <div className="devider" />
          <DisplaySection
            type={updatingBanner.listType}
            identifier={updatingBanner.identifier}
            searchType={updatingBanner.searchAutosuggestBoth}
            setPreviewPageUrl={setPreviewPageUrl}
            onChange={onChangeDisplay}
            className="banner-edit__section"
            language={updatingBanner.language}
            showForAll={updatingBanner.showForAll}
            setshowForAll={(value) => onChangeBanner('showForAll', value)}
          />
          <ContentSection />
          <div className="devider" />
          <TrackingSection
            clickTrackingId={updatingBanner.clickTrackingId}
            viewTrackingId={updatingBanner.viewTrackingId}
          />
        </div>
        <PreviewSection />
      </div>
      {prompt}
    </>
  )
}

export default observer(BannerForm)
