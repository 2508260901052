import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'
import { makeAutoObservable, toJS } from 'mobx'
import { toast } from 'react-toastify'

import api from '../../api'
import SecretKey from '../../models/SecretKey'
import { t } from '../../utils'

class SecretStore {
  secretKeys = []
  permissions = []
  secretDetail = new SecretKey()
  presets = []
  presetPermissionsById = {}
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
  }

  sorter = {
    field: 'changed',
    order: 'descend',
  }

  filter = {}
  state = 'pending' // "pending", "done" or "error"
  isFetchingDetail = true
  isDirty = false

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  *fetchSecretKeys() {
    this.state = 'pending'

    try {
      const { data, total } = yield api.secret.getAll({
        pagination: this.pagination,
        filter: this.filter,
      })

      this.secretKeys = data
      this.pagination.total = total
      this.state = 'done'
    } catch (error) {
      this.state = 'error'
    }
  }

  *setFilter(filter) {
    if (filter.q && filter.q.length < 3) return
    this.pagination = {
      ...this.pagination,
      current: 1,
      total: 0,
    }
    this.filter = filter
    yield this.fetchSecretKeys()
  }

  setPagination(pagination) {
    this.pagination = pagination
    this.fetchSecretKeys()
  }

  *fetchSecretDetail(id) {
    this.isFetchingDetail = true
    this.state = 'pending'

    try {
      yield this.fetchPermission()
      if (id === 'new') {
        this.secretDetail = new SecretKey()
        this.isFetchingDetail = false
        this.state = 'done'
        return
      }

      const { data } = yield api.secret.get(id)
      this.secretDetail = data
      this.isFetchingDetail = false
      this.state = 'done'
    } catch (error) {
      toast.error(t('Secret could not be loaded.'))
      this.isFetchingDetail = false
      this.state = 'error'
    }
  }

  *fetchPermission() {
    const { data } = yield api.permission.getAll('api')
    this.permissions = data
  }

  *updateOrCreate() {
    try {
      this.isDirty = false
      const whiteListFields = [
        'id',
        'isActive',
        'title',
        'description',
        'expiry',
        'permissions',
      ]
      const copyDetail = cloneDeep(toJS(this.secretDetail))
      for (let key of Object.keys(copyDetail)) {
        if (!whiteListFields.includes(key)) {
          delete copyDetail[key]
        }
      }
      if (this.secretDetail.id) {
        yield api.secret.update(copyDetail)
        toast.success(t('Secret was successfully updated.'))
      } else {
        const { data } = yield api.secret.create(copyDetail)
        toast.success(t('Secret was successfully created.'))
        return get(data, 'id')
      }
    } catch (error) {
      this.isDirty = true
      this.state = 'error'
      toast.error('Something went wrong...')
    }
  }

  *delete(ids) {
    try {
      yield api.secret.delete(ids)
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong...')
    }

    this.fetchUsers()
  }

  setSecretDetail(data) {
    this.secretDetail = data
    this.isDirty = true
  }

  onChangePermission(permissionKey, enable) {
    let permissions = this.secretDetail.permissions || []
    if (enable) {
      if (!permissions.includes(permissionKey)) permissions.push(permissionKey)
    } else {
      permissions = permissions.filter((item) => item !== permissionKey)
    }
    this.secretDetail = {
      ...this.secretDetail,
      permissions,
    }
    this.isDirty = true
  }

  setDirty(dirty) {
    this.isDirty = dirty
  }

  reset() {
    this.isFetchingDetail = true
    this.secretDetail = new SecretKey()
    this.isDirty = false
  }

  *fetchPresets() {
    try {
      const { data } = yield api.secret.getPresets()
      this.presets = data
      this.state = 'done'
    } catch (error) {
      this.state = 'error'
    }
  }

  replacePermission(permissions) {
    this.setSecretDetail({
      ...this.secretDetail,
      permissions,
    })
  }

  *fetchPresetPermissions(presetId, replace) {
    if (this.presetPermissionsById[presetId]) {
      if (replace) {
        this.replacePermission(this.presetPermissionsById[presetId].permissions)
      }
      return
    }
    try {
      const { data } = yield api.secret.getPresetPermissions(presetId)
      this.presetPermissionsById[presetId] = data
      if (replace) {
        this.replacePermission(data.permissions)
      }
      this.state = 'done'
    } catch (error) {
      this.state = 'error'
    }
  }
}

export default new SecretStore()
