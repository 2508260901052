import { useEffect } from 'react'
import { CopyOutlined } from '@ant-design/icons'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { observer } from 'mobx-react-lite'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'

import Text from '../../components/Text'
import Container from '../../components/Container'
import PageTitle from '../../components/PageTitle'
import ConfigurationStore from '../../stores/ConfigurationStore'
import t from '../../utils/translate'
import PrivateElement from '../../components/PrivateElement'
import { createRedirectPath } from '../../utils'
import { routes } from '../../routing'

import './installation.styl'

function SetupInformation() {
  const { setup } = ConfigurationStore
  const history = useHistory()

  useEffect(() => {
    ConfigurationStore.fetchSetup()
  }, [])

  if (Object.keys(setup).length === 0) return null

  const handleCopy = () => toast.success(t('Copied') + '!')

  return (
    <>
      <PageTitle>{t('Setup Information')}</PageTitle>

      <div className="content-wrapper setup">
        <div className="setup__section">
          <Text weight="bold" element="h3">
            {t(
              '1. Installation of our Makaira Connect Plugin in your shop system'
            )}
          </Text>
          <Text element="p">
            {t(
              'Our Makaira Connect modules are open source and everyone is invited to contact us at GitHub '
            )}
            <a
              href="https://github.com/makairaio?q=connect&type=all&language=&sort="
              target="_blank"
              rel="noopener noreferrer"
            >
              Github
            </a>
            .
          </Text>
          <Text element="p">
            {t(
              'We are very happy to receive feedback in the form of issues or pull requests.'
            )}
          </Text>
          <br />
          <Text element="p">
            {t(
              'You can find installation instructions for Shopify, OXID, Shopware, Plentymarkets in the '
            )}
            <a
              href="https://docs.makaira.io/docs/shop-integrations"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('Makaira documentation')}
            </a>
            .
          </Text>
        </div>
        <div className="setup__section">
          <Text weight="bold" element="h3">
            {t('2. Configuration of the plugin')}
          </Text>
          <Container className="setup__config" level={-1} padding="s">
            <PrivateElement permission={['secret.read']}>
              <div>
                <span>{t('Shared Secret')}:</span>
                <a
                  onClick={(e) => {
                    e.preventDefault()
                    history.push(createRedirectPath(routes.secretList.path))
                  }}
                >
                  {routes.secretList.title}
                </a>
              </div>
            </PrivateElement>

            <div>
              <span>{t('Makaira URL')}:</span>
              <code style={{ marginRight: 15 }}>{setup.makaira.url}</code>
              <CopyToClipboard text={setup.makaira.url} onCopy={handleCopy}>
                <CopyOutlined className="setup__copy" />
              </CopyToClipboard>
            </div>

            <div>
              <span>{t('Instance')}:</span>
              <code style={{ marginRight: 15 }}>{setup.makaira.instance}</code>
              <CopyToClipboard
                text={setup.makaira.instance}
                onCopy={handleCopy}
              >
                <CopyOutlined className="setup__copy" />
              </CopyToClipboard>
            </div>

            {setup?.makaira?.tracking_id ? (
              <div>
                <span>{t('Tracking ID')}:</span>
                <code style={{ marginRight: 15 }}>
                  {setup.makaira.tracking_id}
                </code>
                <CopyToClipboard
                  text={setup.makaira.tracking_id}
                  onCopy={handleCopy}
                >
                  <CopyOutlined className="setup__copy" />
                </CopyToClipboard>
              </div>
            ) : (
              <div>
                <br />
                {t('Please contact')}{' '}
                <a href="mailto:support@makaira.io">support@makaira.io</a>{' '}
                {t('to get your Tracking set up.')}
              </div>
            )}
          </Container>
          <Text element="p">
            {t('Enter Secret and Makaira URL in the fields provided.')}
          </Text>
        </div>
      </div>
    </>
  )
}

export default observer(SetupInformation)
