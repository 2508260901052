import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import SecretStore from '../../stores/SecretStore'
import ActionLayerStore from '../../stores/ActionLayerStore'
import { Column, ArrowAction } from '../../components/Table'
import ResourceTable from '../../components/ResourceTable'
import ContentWrapper from '../../components/ContentWrapper'
import Text from '../../components/Text'
import t from '../../utils/translate'
import createRedirectPath from '../../utils/createRedirectPath'
import Icon from '../../components/Icon'
import ActiveField from '../../components/ActiveField'
import { useHistory } from 'react-router-dom'
import { getResourceTableFilters, getSymbolCreatedBy } from './utils'
import { Tooltip } from 'antd'
import formatDateNow from '../../utils/formatDateNow'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { toast } from 'react-toastify'

import './style.styl'

function SecretKeyList() {
  const history = useHistory()
  const [selectedRows, setSelectedRows] = useState([])
  const [unHideKeyIds, setUnHideKeyIds] = useState([])

  useEffect(() => {
    SecretStore.fetchSecretKeys()
  }, [])

  const handleDelete = async () => {
    await SecretStore.delete(selectedRows)

    if (SecretStore.state !== 'error') {
      setSelectedRows([])
      return true
    }

    return false
  }

  const showDeleteWarning = () => {
    ActionLayerStore.openActionLayer({
      header: t('delete_modal_title')(selectedRows.length),
      saveTitle: 'OK',
      onSave: handleDelete,
      onClose: () => {},
    })
  }

  return (
    <>
      <ContentWrapper className="secret-list">
        <ResourceTable
          title={t('API Keys')}
          store={SecretStore}
          resourceName="secretKeys"
          loading={SecretStore.state === 'pending'}
          emptyText={t(
            'Nothing found - just create a new secret key or reset your search & filters above.'
          )}
          filters={getResourceTableFilters(SecretStore)}
          filterParams="filter"
          bulkActions={[
            {
              title: t('Delete'),
              onExecute: () => showDeleteWarning(),
            },
          ]}
          rowSelection={{
            selectedRows,
            onSelectionChange: (newRows) => setSelectedRows(newRows),
          }}
          onRowClick={(record) =>
            history.push(createRedirectPath(`/secret/${record.id}`))
          }
          hideEmptyTextWhenNoFilters
          headerAction={{
            type: 'create',
            action: () => history.push(createRedirectPath('/secret/new')),
          }}
          createActionText={t('Add API Key')}
          createAction={() => history.push(createRedirectPath('/secret/new'))}
        >
          <Column
            width={70}
            title={t('Active')}
            align="center"
            dataIndex="isActive"
            render={(value) => <ActiveField active={value} />}
            sorter={true}
          />
          <Column
            width={120}
            title={t('Created By')}
            align="center"
            dataIndex="createdBy"
            render={(value) => (
              <Icon
                width="24px"
                height="24px"
                symbol={getSymbolCreatedBy(value)}
              />
            )}
            sorter={true}
          />
          <Column
            title={t('Name')}
            render={(_, record) => (
              <div className="secret-list__title">
                {record.title}
                <Tooltip title={record.description}>
                  <Icon
                    width="12px"
                    height="12px"
                    symbol="circle-info__regular"
                  />
                </Tooltip>
              </div>
            )}
          />

          <Column
            width={200}
            title={t('Expiration')}
            render={(_, record) => {
              if (!record.expiry?.date || !record.expiry?.timezone) {
                return <></>
              }
              return (
                <Text>
                  {formatDateNow(record.expiry.date, record.expiry.timezone)}
                </Text>
              )
            }}
          />
          <Column
            width={300}
            title={t('Key')}
            dataIndex="maskedSecret"
            render={(_, record) => {
              return unHideKeyIds.includes(record.id)
                ? record.plainSecret
                : record.maskedSecret
            }}
          />
          <Column
            width={50}
            align="center"
            dataIndex="plainSecret"
            onCell={() => {
              return {
                onClick: (event) => {
                  event.stopPropagation()
                },
              }
            }}
            render={(value) => {
              if (!value) return null

              return (
                <CopyToClipboard
                  text={value}
                  onCopy={() => toast.success(t('Secret copied!'))}
                >
                  <Icon width="30px" height="30px" symbol={'copy'} />
                </CopyToClipboard>
              )
            }}
          />
          <Column
            width={50}
            align="center"
            dataIndex="id"
            onCell={() => {
              return {
                onClick: (event) => {
                  event.stopPropagation()
                },
              }
            }}
            render={(value, record) => {
              return (
                <Icon
                  width="30px"
                  height="30px"
                  symbol={unHideKeyIds.includes(value) ? 'eye-slash' : 'eye'}
                  onClick={() => {
                    if (record.plainSecret) {
                      setUnHideKeyIds((s) => {
                        return unHideKeyIds.includes(value)
                          ? s.filter((i) => i !== value)
                          : [...s, value]
                      })
                    } else {
                      toast.error(t('Not enough permissions.'))
                    }
                  }}
                />
              )
            }}
          />
          <Column width={50} render={() => <ArrowAction />} />
        </ResourceTable>
      </ContentWrapper>
    </>
  )
}

export default observer(SecretKeyList)
