import { observer } from 'mobx-react-lite'
import * as Yup from 'yup'
import { Formik } from 'formik'

import { EditorStore } from '../../../stores'
import TextInput from '../../../components/TextInput'
import { t } from '../../../utils'
import Text from '../../../components/Text'
import Textarea from '../../../components/Textarea'
import Select from '../../../components/Select'
import UpdateStoreDirtyState from '../../../components/UpdateStoreDirtyState'
import FormikOnChange from '../../../components/FormikOnChange'

const SEOSettings = ({ formikRef }) => {
  const { setMetadataField, metadata, contentLanguage } = EditorStore

  const schema = Yup.object().shape({
    canonicalUrl: Yup.string().url('Please enter a valid URL'),
  })

  return (
    <div className="page-editor__seo-settings">
      <TextInput
        label={<Text variant="book">{t('Focus Keyword')}</Text>}
        value={metadata.keywords || ''}
        onChange={(e) => setMetadataField('keywords', e.target.value)}
      />
      <TextInput
        label={<Text variant="book">{t('SEO Title')}</Text>}
        value={metadata.seoTitle || ''}
        onChange={(e) => setMetadataField('seoTitle', e.target.value)}
        placeholder={metadata.title}
      />
      <Textarea
        title={<Text variant="book">{t('Meta Description')}</Text>}
        value={metadata.description || ''}
        onChange={(value) => setMetadataField('description', value)}
      />
      <Formik
        innerRef={formikRef}
        initialValues={{
          canonicalUrl: metadata.canonicalUrl,
        }}
        validationSchema={schema}
        key={contentLanguage}
      >
        {(formikProps) => (
          <>
            <UpdateStoreDirtyState store={EditorStore} />
            <FormikOnChange
              onChange={(values) =>
                setMetadataField('canonicalUrl', values.canonicalUrl)
              }
            />
            <TextInput
              label={<Text variant="book">{t('Canonical Url')}</Text>}
              value={formikProps.values.canonicalUrl || ''}
              onChange={(e) =>
                formikProps.setFieldValue('canonicalUrl', e.target.value)
              }
              error={t(formikProps.errors.canonicalUrl)}
            />
          </>
        )}
      </Formik>

      <Select
        title={<Text variant="book">{t('Robot Index')}</Text>}
        value={metadata.robotIndex}
        options={[
          { label: 'index', value: 'index' },
          { label: 'noindex', value: 'noindex' },
        ]}
        onChange={(value) => setMetadataField('robotIndex', value)}
      />
      <Select
        title={<Text variant="book">{t('Robot Follow')}</Text>}
        value={metadata.robotFollow}
        options={[
          { label: 'follow', value: 'follow' },
          { label: 'nofollow', value: 'nofollow' },
        ]}
        onChange={(value) => setMetadataField('robotFollow', value)}
      />
    </div>
  )
}

export default observer(SEOSettings)
