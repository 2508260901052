import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'

import ResourceTable from '../../components/ResourceTable'
import ContentWrapper from '../../components/ContentWrapper'
import { Column, ArrowAction } from '../../components/Table'
import Text from '../../components/Text'
import Badge from '../../components/Badge'
import UsageModal from './components/UsageModal'
import Icon from '../../components/Icon'

import ActionLayerStore from '../../stores/ActionLayerStore'
import StreamStore from '../../stores/StreamStore'
import t from '../../utils/translate'
import { createRedirectPath } from '../../utils'
import Button from '../../components/Button'
import { UIStore } from '../../stores'

import './list.styl'

function StreamList() {
  const history = useHistory()
  const [selectedRows, setSelectedRows] = useState([])

  useEffect(() => {
    if (UIStore.documentTypes.length === 0) {
      UIStore.fetchDocumentTypes()
    }
    StreamStore.fetchStreams(true)
    // eslint-disable-next-line
  }, [])

  const showDeleteWarning = () => {
    ActionLayerStore.openActionLayer({
      header: t('delete_modal_title')(selectedRows.length),
      saveTitle: 'OK',
      onSave: handleDelete,
      onClose: () => {},
    })
  }

  const handleDelete = async () => {
    if (!selectedRows.length) return

    await StreamStore.delete(selectedRows)

    if (StreamStore.state !== 'error') {
      setSelectedRows([])
      return true
    }

    return false
  }

  return (
    <ContentWrapper className="product-stream-list">
      <ResourceTable
        title={t('Streams')}
        store={StreamStore}
        resourceName="streams"
        loading={StreamStore.state === 'pending'}
        emptyText={t(
          'Nothing found - just create a new stream or reset your search & filters above.'
        )}
        filterParams="filter"
        filters={[
          {
            type: 'single-select',
            title: t('Used in'),
            placeholder: t('Select'),
            name: 'filterStream',
            onChange: StreamStore.setFilter,
            options: [
              { value: 'all', label: t('All') },
              { value: 'boosting', label: t('with Boosting') },
              { value: 'categories', label: t('used in Categories') },
              { value: 'feeds', label: t('used in Feeds') },
              { value: 'unused', label: t('unused Streams') },
            ],
            value: StreamStore.filter.filterStream,
          },
          {
            type: 'single-select',
            title: t('Type'),
            placeholder: t('Select'),
            name: 'datatype',
            onChange: StreamStore.setFilter,
            options: UIStore.documentTypes.map((type) => ({
              label: type.title,
              value: type.id,
            })),
            value: StreamStore.filter.datatype,
          },
          {
            type: 'search',
            title: t('Search'),
            name: 'q',
            onChange: StreamStore.setFilter,
          },
        ]}
        bulkActions={[
          {
            title: t('Delete'),
            onExecute: () => showDeleteWarning(),
          },
        ]}
        rowSelection={{
          selectedRows,
          checkDisable: (record) =>
            !(
              record.categoryCount === 0 &&
              record.productFeedCount === 0 &&
              !record.isUsedByLandingpage
            ),
          onSelectionChange: (newRows) => setSelectedRows(newRows),
        }}
        onRowClick={(record) =>
          history.push(createRedirectPath(`/stream/${record.id}`))
        }
        hideEmptyTextWhenNoFilters
        headerAction={{
          type: 'create',
          action: () => history.push(createRedirectPath('/stream/new')),
        }}
        createActionText={t('Add Streams')}
        createAction={() => history.push(createRedirectPath('/stream/new'))}
      >
        <Column
          title={t('Name (ID)')}
          render={(title, record) => (
            <Text weight="bold" element="div">
              {title}
              <span className="title-id">({record.id})</span>
            </Text>
          )}
          dataIndex="title"
          sorter={true}
        />
        <Column title={t('Type')} dataIndex="datatype" width={200} />
        <Column
          title={t('Used in...')}
          dataIndex="listType"
          width={'40%'}
          render={(_, record) => {
            const categories = parseInt(record.categoryCount, 10)
            const productFeeds = parseInt(record.productFeedCount, 10)
            const boostFactor = parseInt(record.boostFactor, 10)
            const isUsedByLandingpage = record.isUsedByLandingpage

            const BadgeIcon = ({ type, text, id }) => {
              return (
                <Badge
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    StreamStore.openStreamDialog(id, type)
                  }}
                  text={
                    <div className="usage-tag">
                      {text}
                      <Icon
                        symbol="circle-info__solid"
                        width={15}
                        height={15}
                      />
                    </div>
                  }
                />
              )
            }
            return (
              <div className="badges">
                {isUsedByLandingpage && (
                  <BadgeIcon
                    text={t('Landingpage')}
                    id={record.id}
                    type="landingpage"
                  />
                )}
                {categories > 0 && (
                  <BadgeIcon
                    text={t('Category')}
                    id={record.id}
                    type="category"
                  />
                )}
                {productFeeds > 0 && (
                  <BadgeIcon text={t('Feed')} id={record.id} type="feed" />
                )}
                {boostFactor !== 0 && <Badge text={t('Boost')} />}
              </div>
            )
          }}
        />
        <Column
          width={50}
          render={(_, record) => (
            <Button
              tooltip={t('Copy Stream')}
              level={1}
              variant="tertiary"
              icon="copy"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                StreamStore.copyStream({
                  stream: record.id,
                  title: record.title + ' (Copy)',
                })
              }}
            />
          )}
        />
        <Column width={50} render={() => <ArrowAction />} />
      </ResourceTable>
      <UsageModal />
    </ContentWrapper>
  )
}

export default observer(StreamList)
