import { makeAutoObservable, toJS, set } from 'mobx'
import { toast } from 'react-toastify'
import api from '../../api'
import { Banner } from '../../models'
import { prepareImageUrl } from '../../utils'
import { searchTypes } from '../../components/DisplaySection/constant'
import { get } from 'lodash'

class BannerStore {
  banners = []
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
  }
  sorter = {
    field: 'activeFrom',
    order: 'descend',
  }
  filter = {}
  state = 'pending' // "pending", "done" or "error"

  /**************** Banner Detail ********************/
  bannerTypes = []
  deviceTypes = []
  datatypes = []
  isDirty = false
  isFetchingDetail = false
  originalBanner = {}
  updatingBanner = {}
  showTimeControlModal = false

  /******************* PREVIEW DATA *****************/
  previewState = 'pending'
  previewList = []
  previewPage = 0
  previewMaxPage = 0
  previewProductTotal = 0
  previewProductsCount = 20
  previewPageUrl = null
  samepleSearchByType = {}
  /*************************************************/

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  reset() {
    this.pagination = {
      current: 1,
      pageSize: 10,
      total: 0,
    }
  }

  setPagination(pagination) {
    this.pagination = pagination
    this.fetchBanners()
  }

  setSorter(sorter) {
    this.reset()

    this.sorter = sorter
    this.fetchBanners()
  }

  setFilter(filter) {
    this.reset()

    this.filter = filter
    this.fetchBanners()
  }

  getById(id) {
    return this.banners.find((s) => s.id == id)
  }

  *fetchBanners() {
    try {
      this.state = 'pending'
      const { data, total } = yield api.banners.getAll({
        pagination: this.pagination,
        filter: this.filter,
        sorter: this.sorter,
      })

      this.banners = data
      this.pagination.total = total
      this.state = 'done'
    } catch (error) {
      toast.error('Something went wrong loading the banner listing.')
      this.state = 'error'
    }
  }

  *fetchBannerById(id, defaultLanguage) {
    try {
      this.isDirty = false
      this.originalBanner = {}
      this.updatingBanner = {}
      this.isFetchingDetail = true
      yield this.fetchBannerType()
      if (id === 'new') {
        const newBanner = new Banner()
        newBanner.language = defaultLanguage
        newBanner.type = this.bannerTypes[0]?.id
        newBanner.searchAutosuggestBoth = searchTypes[0].id
        this.originalBanner = newBanner
        this.updatingBanner = newBanner
      } else {
        const { data } = yield api.banners.getById(id)
        this.originalBanner = data
        this.updatingBanner = data
      }
    } catch (error) {
      toast.error('Something went wrong loading the banner detail.')
      this.state = 'error'
    } finally {
      this.state = 'done'
      this.isFetchingDetail = false
    }
  }

  async updateOrCreate() {
    try {
      this.setDirty(false)
      if (this.updatingBanner.id) {
        const { data } = await api.banners.update(this.updatingBanner)
        return data
      } else {
        const { data } = await api.banners.create(this.updatingBanner)
        this.updatingBanner.id = data.id
        return data
      }
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong...')
      return null
    }
  }

  *delete(ids) {
    try {
      yield api.banners.delete(ids)
    } catch (error) {
      this.state = 'error'
    }

    this.fetchBanners()
  }

  *fetchBannerType() {
    try {
      const { data } = yield api.banners.bannerType()
      this.bannerTypes = data
      this.state = 'done'
    } catch (error) {
      toast.error('Something went wrong loading the banner type.')
      this.state = 'error'
    }
  }

  *fetchDeviceType() {
    try {
      const { data } = yield api.banners.deviceType()
      this.deviceTypes = data
      this.state = 'done'
    } catch (error) {
      toast.error('Something went wrong loading the device type.')
      this.state = 'error'
    }
  }

  // support show Link type
  *fetchDataTypes() {
    if (this.datatypes.length) return

    this.state = 'pending'
    try {
      const { data } = yield api.fields.getFieldsByType('datatype')

      this.datatypes = data
        .filter((field) => {
          return !['searchredirect'].includes(field.id)
        })
        .map((field) => ({
          value: field.id,
          label: field.singular,
          singular: field.singular,
        }))

      this.state = 'done'
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong...')
    }
  }

  async searchDocuments(datatype, searchPhrase, language, shopId, fields = []) {
    const searchPhraseCleaned = searchPhrase.trim()
    if (searchPhraseCleaned === '') {
      return []
    }

    try {
      const data = await api.common.fetchSearchResultPreview({
        searchPhrase: searchPhraseCleaned,
        shopId: shopId,
        datatypes: [datatype],
        fields: [
          'id',
          'ean',
          'title',
          'datatype',
          'picture_url_main',
          ...fields,
        ],
        state: 'active',
        showEverything: false,
        language,
      })
      if (data[datatype].total > 0) {
        return data[datatype].items.map((item) => item.fields)
      }
      return []
    } catch (error) {
      this.state = 'error'
      toast.error('Could not fetch search result.')
      return []
    }
  }

  async getDocumentByIds(type, ids, language) {
    if (!ids || ids.length === 0) {
      return Promise.resolve([])
    }
    return api.common.getDocumentsById({ type, ids, language })
  }

  onChangeBanner(key, value) {
    set(this.updatingBanner, key, value)
    this.isDirty = true
  }

  resetBanner() {
    this.updatingBanner = { ...toJS(this.originalBanner) }
    this.isDirty = false
  }

  onShowControlModal(show) {
    this.showTimeControlModal = show
  }

  /**
   *
   * @param {{ title, alt, data}} image
   */
  updateBannerImages(banner, image) {
    if (!banner.images) banner.images = []
    const index = banner.images.findIndex(
      (item) => item.deviceTypeId === image.deviceTypeId
    )
    if (index >= 0) {
      banner.images[index] = { ...banner.images[index], ...image }
    } else {
      banner.images.push(image)
    }
    this.setDirty(true)
  }

  setDirty(dirty) {
    this.isDirty = dirty
  }

  /*********************** HANDLE PREVIEW FLOW ********************************/

  *fetchBannerPreviewList(request) {
    this.previewState = 'pending'

    const { currentLanguage, shopId, page = 1 } = request

    let previewPageUrl = this.previewPageUrl
    let searchPhrase =
      this.updatingBanner.listType === 'search'
        ? this.updatingBanner.identifier?.[0]
        : null

    if (this.updatingBanner.showForAll) {
      if (this.updatingBanner.listType === 'search') {
        searchPhrase = get(this.samepleSearchByType, 'product[0].title[0]', 'a')
      } else {
        previewPageUrl = get(
          this.samepleSearchByType,
          `${this.updatingBanner.listType}[0].url`,
          ''
        )
      }
    }

    if (
      (!searchPhrase && this.updatingBanner.listType === 'search') ||
      (!previewPageUrl && this.updatingBanner.listType !== 'search')
    ) {
      this.previewList = []
      return
    }

    try {
      const response = yield api.enterprise.fetchEnterprisePage({
        searchPhrase,
        shopId,
        count: this.previewProductsCount,
        offset: (page - 1) * this.previewProductsCount,
        language: currentLanguage,
        page,
        url: previewPageUrl,
      })
      const data =
        this.updatingBanner.listType === 'search' ? response : response.data
      if (data.product && data.product.items.length > 0) {
        this.previewList = data.product.items
        this.previewMaxPage = Math.ceil(
          data.product.total / this.previewProductsCount
        )
        this.previewPage = Math.floor(
          data.product.offset / this.previewProductsCount
        )
        this.previewProductTotal = data.product.total
      }
      this.previewState = 'done'
    } catch (error) {
      this.previewState = 'error'
      toast.error('Something went wrong...')
    }
  }

  setPreviewPageUrl(url) {
    this.previewPageUrl = url
  }

  isBannerInPreviewList() {
    const offset = this.previewPage * this.previewProductsCount + 1
    const maxOffset = (this.previewPage + 1) * this.previewProductsCount

    // check banner position is in current preview page or not
    if (
      this.updatingBanner.position >= offset &&
      this.updatingBanner.position <= maxOffset + 1
    )
      return true

    // If the banner position higher than total product of the page and current page is last page
    if (
      this.updatingBanner.position >= this.previewProductTotal &&
      this.previewMaxPage === this.previewPage
    )
      return true

    return false
  }

  getPreviewProductList(enterpriseConfiguration) {
    const image = this.updatingBanner.images?.find((item) => item.data)
    if (this.previewList.length > 0) {
      let banners = []
      if (this.isBannerInPreviewList()) {
        banners.push({
          id: this.updatingBanner.id,
          type: 'banner',
          fields: {
            title: this.updatingBanner.title,
            picture_url_main:
              image &&
              prepareImageUrl({
                source: image?.data,
                ...enterpriseConfiguration,
              }).url,
            description: 'Banner',
          },
        })
      }
      const offset = this.previewPage * this.previewProductsCount
      const indexSlice = this.updatingBanner.position - offset - 1

      // if banner is in last position of current page
      if (indexSlice === this.previewList.length) {
        return [...this.previewList, ...banners]
      }

      return [
        ...this.previewList.slice(0, this.updatingBanner.position - offset - 1),
        ...banners,
        ...this.previewList.slice(this.updatingBanner.position - offset - 1),
      ]
    }
    return this.previewList
  }

  *getDocumentByType(type) {
    try {
      const { data } = yield api.common.getDocumentsByType(type)
      this.samepleSearchByType[type] = data
    } catch (error) {
      console.log(error)
    }
  }

  getSampleDataForPreview() {
    try {
      const types = ['product', 'category', 'manufacturer']
      types.forEach((type) => this.getDocumentByType(type))
    } catch (error) {
      console.log(error)
    }
  }
}

export default new BannerStore()
