import { observer } from 'mobx-react-lite'

import Modal from '../../components/Modal'
import Table, { Column } from '../../components/Table'
import Text from '../../components/Text'

import t from '../../utils/translate'

import { useEffect, useState } from 'react'
import api from '../../api'
import { PersonasStore } from '../../stores'
import './usageModal.styl'

function UsageModal({ id, type, onClose }) {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  const getLandingpageUsage = async (id) => {
    setLoading(true)
    try {
      const { data } = await api.common.getUsagePersona(id)
      setData(data || [])
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!id || !type) {
      setData([])
      return
    }

    if (type === 'landingpage') {
      getLandingpageUsage(id)
    } else {
      const persona = PersonasStore.personas.find((p) => p.id === id)
      setData(persona ? persona[type] : [])
    }
  }, [id, type])

  return (
    <Modal
      className="product-stream__usage-modal"
      visible={!!id}
      header={t('Persona usage')}
      onClose={onClose}
      width={800}
      centered={true}
      footer={null}
    >
      <Table data={data} loading={loading}>
        <Column
          title="ID"
          render={(id) => (
            <div className="cell-title">
              <Text weight="bold" element="span">
                {id}
              </Text>
            </div>
          )}
          dataIndex="id"
          width={150}
        />
        <Column
          title={t('Name')}
          render={(title) => (
            <div className="cell-title">
              <Text weight="bold" element="span">
                {title}
              </Text>
            </div>
          )}
          dataIndex="title"
        />
      </Table>
    </Modal>
  )
}

export default observer(UsageModal)
