import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef, useState } from 'react'
import Button from '../../components/Button'
import ContentWrapper from '../../components/ContentWrapper'
import DropdownButton from '../../components/DropdownButton'
import PageTitle from '../../components/PageTitle'
import Select from '../../components/Select'
import { Column } from '../../components/Table'
import Text from '../../components/Text'
import TextInput from '../../components/TextInput'
import { ActionLayerStore, RedirectStore, UIStore } from '../../stores'
import { t } from '../../utils'
import PrefillModal from './PrefillModal'
import ResourceTable from '../../components/ResourceTable'

import './style.styl'

const Redirects = () => {
  const [prefillModalVisible, setPrefillModalVisible] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const fileRef = useRef()

  useEffect(() => {
    RedirectStore.fetchRedirects()
    UIStore.fetchDocumentTypes()
  }, [])

  const handleDelete = async () => {
    if (!selectedRows.length) return

    await RedirectStore.delete(selectedRows)

    if (RedirectStore.state !== 'error') {
      setSelectedRows([])
      return true
    }

    return false
  }

  const showDeleteWarning = () => {
    ActionLayerStore.openActionLayer({
      header: t('delete_modal_title')(selectedRows.length),
      saveTitle: 'OK',
      onSave: handleDelete,
      onClose: () => {},
    })
  }

  const filters = [
    {
      type: 'single-select',
      title: t('Redirect Code'),
      placeholder: t('Select'),
      name: 'code',
      value: RedirectStore.filter?.code,
      options: [
        { label: 'Show All', value: '' },
        { label: '301 - Permanent', value: '301' },
        { label: '302 - Temporary', value: '302' },
      ],
      onChange: RedirectStore.setFilter,
    },
    {
      type: 'search',
      title: t('Search'),
      name: 'q',
      onChange: RedirectStore.setFilter,
      defaultValue: RedirectStore.filter?.q,
    },
  ]

  const closePrefillModal = () => setPrefillModalVisible(false)
  const clientBaseurl = UIStore.enterpriseConfiguration?.clientBaseurl ?? ''

  return (
    <>
      <PageTitle
        prefix={t('You are configuring')}
        hiddenFields={['internal-title']}
      >
        {t('Redirects')}
      </PageTitle>
      <ContentWrapper className="settings">
        <div className="settings__redirect">
          <div className="settings__redirect-actions">
            <Text
              variant="book"
              element="p"
              className="settings__public-fields__desc"
            >
              {t(
                'Assign the fields from the source system to the respective views'
              )}
            </Text>
            <div className="settings__redirect-footer">
              <div className="settings__redirect-footer-actions">
                <Button
                  onClick={RedirectStore.addNewRedirect}
                  variant="tertiary"
                  icon="plus"
                  iconPosition="left"
                >
                  {t('Add a redirect')}
                </Button>
                <Button
                  variant="tertiary"
                  icon="upload"
                  iconPosition="left"
                  onClick={() => fileRef.current?.click()}
                  loading={RedirectStore.uploadingCSV}
                >
                  {t('Upload .csv')}
                </Button>
                <input
                  onChange={(e) =>
                    RedirectStore.createWithCSV(e.target.files[0])
                  }
                  ref={fileRef}
                  accept="text/csv"
                  type="file"
                  hidden
                />
                <Button
                  onClick={RedirectStore.createCSV}
                  variant="tertiary"
                  icon="download"
                  iconPosition="left"
                >
                  {t('Download .csv')}
                </Button>
              </div>
            </div>
          </div>

          <div className="">
            <ResourceTable
              filters={filters}
              emptyText="No data"
              filterParams="filter"
              store={RedirectStore}
              resourceName="redirects"
              loading={RedirectStore.state === 'pending'}
              rowKey="id"
              bulkActions={[
                {
                  title: t('Delete'),
                  onExecute: () => showDeleteWarning(),
                },
              ]}
              rowSelection={{
                selectedRows,
                onSelectionChange: (newRows) => setSelectedRows(newRows),
              }}
              p
            >
              <Column
                title={t('Source')}
                dataIndex="source"
                render={(data, record) =>
                  ['edit', 'create'].includes(record.state) ? (
                    <TextInput
                      value={record.source}
                      onChange={(e) =>
                        RedirectStore.updateRedirectState(
                          record,
                          'source',
                          e.target.value
                        )
                      }
                      autoFocus
                    />
                  ) : (
                    <Text className="text-truncated" weight="bold">
                      {data}
                    </Text>
                  )
                }
              />
              <Column
                title={t('Redirection Code')}
                dataIndex="code"
                width={160}
                render={(data, record) =>
                  ['edit', 'create'].includes(record.state) ? (
                    <Select
                      value={record.code?.toString()}
                      onChange={(value) =>
                        RedirectStore.updateRedirectState(
                          record,
                          'code',
                          parseInt(value)
                        )
                      }
                      options={[
                        { label: '301 - Permanent', value: '301' },
                        { label: '302 - Temporary', value: '302' },
                      ]}
                      flexible
                      dropdownMatchSelectWidth={false}
                    />
                  ) : (
                    <Text className="settings__redirect-code" weight="bold">
                      {data}
                    </Text>
                  )
                }
              />
              <Column
                title={t('Priority')}
                dataIndex="priority"
                width={160}
                render={(data, record) =>
                  ['edit', 'create'].includes(record.state) ? (
                    <TextInput
                      type={'number'}
                      min="0"
                      max="100"
                      value={record.priority}
                      onChange={(e) =>
                        RedirectStore.updateRedirectState(
                          record,
                          'priority',
                          parseInt(e.target.value)
                        )
                      }
                    />
                  ) : (
                    <Text weight="bold">{data}</Text>
                  )
                }
              />
              <Column
                title={t('Target')}
                dataIndex="target"
                render={(data, record) => (
                  <div>
                    {['edit', 'create'].includes(record.state) ? (
                      <div className="settings__edit-row settings__edit-row--editing">
                        <TextInput
                          onChange={(e) =>
                            RedirectStore.updateRedirectState(
                              record,
                              'target',
                              e.target.value
                            )
                          }
                          value={record.target}
                          icon={{
                            symbol: 'link__solid',
                            onClick: () => setPrefillModalVisible(true),
                            tooltip: t('Prefill entry'),
                          }}
                        />
                        <Button
                          level={1}
                          variant="tertiary"
                          icon="check"
                          onClick={() => RedirectStore.updateOrCreate(record)}
                          disabled={!record.target || !record.source}
                        />
                        <PrefillModal
                          closePrefillModal={closePrefillModal}
                          prefillModalVisible={prefillModalVisible}
                          onSelect={({ item }) =>
                            RedirectStore.updateRedirectState(
                              record,
                              'target',
                              `${clientBaseurl}/${item.url}`
                            )
                          }
                        />
                      </div>
                    ) : (
                      <div className="settings__edit-row">
                        <Text className="text-truncated" weight="bold">
                          {data}
                        </Text>
                        <Button
                          level={1}
                          variant="tertiary"
                          icon="pencil"
                          onClick={() =>
                            RedirectStore.updateRedirectState(
                              record,
                              'state',
                              'edit'
                            )
                          }
                        />

                        <DropdownButton
                          actionIcon="trash"
                          menu={(hide) => (
                            <div className="settings__delete-confirm">
                              <Text size="charlie">
                                {t('Delete redirect now?')}
                              </Text>
                              <div>
                                <Button onClick={hide}>{t('Cancel')}</Button>
                                <Button
                                  onClick={() => {
                                    RedirectStore.delete(record.id)
                                    hide()
                                  }}
                                  variant="primary"
                                >
                                  {t('Ok')}
                                </Button>
                              </div>
                            </div>
                          )}
                          buttonLevel={1}
                          destroyTooltipOnHide
                          iconOnly
                        />
                      </div>
                    )}
                  </div>
                )}
              />
            </ResourceTable>
          </div>
        </div>
      </ContentWrapper>
    </>
  )
}

export default observer(Redirects)
