import { Tooltip } from 'antd'
import { observer } from 'mobx-react-lite'
import get from 'lodash/get'
import Button from '../../../components/Button'
import ChangedField from '../../../components/ChangedField'
import ConfirmModal from '../../../components/Modal/ConfirmModal'
import Table, { Column } from '../../../components/Table'
import Text from '../../../components/Text'
import MakairaGravatar from '../../../components/MakairaGravatar'
import { AuditStore, EditorStore, RevisionStore } from '../../../stores'
import { t } from '../../../utils'
import { useState } from 'react'
import DiffModal from '../../AuditLogs/DiffModal'

const Revisions = () => {
  const { pageToEdit, originDetailData } = EditorStore
  const [diffModal, setDiffModal] = useState(false)
  console.log(originDetailData, diffModal)

  const {
    revisions,
    restoreRevision,
    closeConfirmRestoreModal,
    isConfirmRestoreModalVisible,
  } = RevisionStore

  const handleRestoreRevision = (revision) => restoreRevision(revision)

  const handleCloseConfirmModal = () => closeConfirmRestoreModal()

  const { type } = pageToEdit

  return (
    <div className="page-configuration__revisions">
      <Table
        rowKey="id"
        emptyContainerSize="small"
        emptyText={t(
          'Here you will find older versions of your page.\nEach save creates a new revision. We keep 20 revisions at max.'
        )}
        data={revisions}
      >
        {(type === 'page' || type === 'snippet') && (
          <Column
            title={t('Name')}
            dataIndex="metadata"
            render={(_, record) => (
              <Text>
                {get(record, [
                  'metadata',
                  EditorStore.contentLanguage,
                  'title',
                ])}
              </Text>
            )}
          />
        )}

        <Column
          width={80}
          title={t('Author')}
          className="page-configuration__revisions--avatar"
          dataIndex="user"
          render={(item) =>
            item && (
              <Tooltip title={item}>
                <span>
                  <MakairaGravatar email={item} />
                </span>
              </Tooltip>
            )
          }
        />
        <Column
          title={t('Last changed')}
          dataIndex="changed"
          render={(_, record) => <ChangedField {...record} withoutUser />}
        />
        <Column
          render={(_, record) => (
            <>
              <Button
                onClick={() => setDiffModal(record)}
                style={{ marginRight: 10 }}
                type="button"
                level={1}
              >
                {t('Show changes')}
              </Button>
              <Button
                onClick={() => handleRestoreRevision(record)}
                type="button"
                level={1}
              >
                {t('Restore')}
              </Button>
            </>
          )}
        />
      </Table>

      <ConfirmModal
        visible={isConfirmRestoreModalVisible}
        onClose={handleCloseConfirmModal}
        onSubmit={() => handleRestoreRevision()}
        mask
      />
      <DiffModal
        visible={diffModal}
        header={t('View Changes')}
        s
        onClose={() => setDiffModal(false)}
        width={1000}
        centered={true}
        mask
        oldValue={AuditStore.transformData(originDetailData)}
        newValue={AuditStore.transformData(diffModal)}
      />
    </div>
  )
}

export default observer(Revisions)
