import { makeAutoObservable } from 'mobx'
import { toast } from 'react-toastify'

import api from '../../api'
import { t } from '../../utils'
import { Role } from '../../models'

class RoleManagementStore {
  roles = []
  permissions = []
  roleDetail = {
    permissions: [],
  }

  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
  }

  sorter = {
    field: 'changed',
    order: 'descend',
  }

  filter = {}
  state = 'pending' // "pending", "done" or "error"
  hideEmptyComponent = false
  resendingUserId = null
  isDirty = false
  rolePrefix = 'local'
  isFetchingDetail = false

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
    this.updateRolePrefix()
  }

  *fetchRoles(getAll) {
    this.state = 'pending'

    let params = {
      pagination: this.pagination,
      filter: this.filter,
    }

    if (getAll) {
      params = {
        pagination: {
          current: 1,
          pageSize: 9999,
        },
      }
    }

    try {
      const { data, total } = yield api.rolesManagement.getAll(params)
      this.roles = data
      this.pagination.total = total
      this.state = 'done'
    } catch (error) {
      this.state = 'error'
    }
  }

  *fetchPermission() {
    try {
      const { data } = yield api.permission.getAll('feature', false)
      this.permissions = data
    } catch (error) {
      this.state = 'error'
    }
  }

  *fetchRoleDetail(roleId) {
    this.isFetchingDetail = true
    try {
      yield this.fetchPermission()

      if (roleId === 'create' || roleId === 'new') {
        this.roleDetail = new Role()
        this.isFetchingDetail = false
        return
      }

      const { data } = yield api.rolesManagement.getDetail(roleId)
      this.roleDetail = data
      this.isFetchingDetail = false
    } catch (error) {
      this.isFetchingDetail = false
    }
  }

  *setFilter(filter) {
    if (filter.q && filter.q.length < 3) return
    this.pagination = {
      ...this.pagination,
      current: 1,
      total: 0,
    }
    this.filter = filter
    yield this.fetchRoles()
    this.hideEmptyComponent = true
  }

  setPagination(pagination) {
    this.pagination = pagination
    this.fetchRoles()
  }

  *updateOrCreate() {
    this.state = 'pending'
    const detail = {
      ...this.roleDetail,
      name: !this.roleDetail.name?.startsWith(this.rolePrefix)
        ? this.getRoleNameWithPrefix(this.roleDetail.name)
        : this.roleDetail.name,
    }
    try {
      this.isDirty = false
      if (this.roleDetail.id) {
        yield api.rolesManagement.update(detail)
        toast.success(t('Role was successfully updated.'))
        this.state = 'done'
        return this.roleDetail.id
      } else {
        const { data } = yield api.rolesManagement.create(detail)
        this.state = 'done'
        return data.role.id
      }
    } catch (error) {
      this.state = 'error'
      this.isDirty = true
      toast.error('Something went wrong...')
    }
  }

  *delete(ids) {
    try {
      yield api.rolesManagement.delete(ids)
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong...')
    }

    this.fetchRoles()
  }

  setRoleDetail(data) {
    this.roleDetail = data
    this.isDirty = true
  }

  setDirty(dirty) {
    this.isDirty = dirty
  }

  reset() {
    this.roleDetail = {
      permissions: [],
    }
    this.isDirty = false
    this.state = 'done'
  }

  updateRolePrefix() {
    if (
      process.env.REACT_APP_PROXY_STAGE &&
      process.env.NODE_ENV === 'development'
    ) {
      this.rolePrefix = 'stage'
    } else {
      this.rolePrefix = window.location.host
        .replace('.makaira.io', '')
        .replace('makaira.vm', 'local')
        .replace(/localhost:\d*/, 'local')
        .toLowerCase()
    }
  }

  addPermission(permission = '') {
    this.roleDetail.permissions = [...this.roleDetail.permissions, permission]
    this.isDirty = true
  }

  removePermission(permission = '') {
    const removedPermissions = this.roleDetail.permissions?.filter(
      (p) => p !== permission
    )
    this.roleDetail.permissions = removedPermissions
    this.isDirty = true
  }

  getRoleName(name = '') {
    console.log(
      'getRoleName',
      name,
      name.replace(`${this.rolePrefix}.`, '').replace('Makaira.', '')
    )

    return name.replace(`${this.rolePrefix}.`, '').replace('Makaira.', '')
  }

  getRoleNameWithPrefix(name = '') {
    return `${this.rolePrefix}.${name}`
  }
}

export default new RoleManagementStore()
