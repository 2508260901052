import { buildQueryString } from '../utils'
import { fetchUtil as fetch } from '../utils/fetchUtil'

export default {
  async get(id) {
    return fetch(`/secret/${id}`)
  },

  async getAll(options = {}) {
    const queryString = buildQueryString(options)

    const target = `/secret?${queryString}`

    return fetch(target)
  },

  async create(secret) {
    return fetch('/secret', { method: 'POST', body: JSON.stringify(secret) })
  },

  async update(secret) {
    return fetch(`/secret/${secret.id}`, {
      method: 'PUT',
      body: JSON.stringify(secret),
    })
  },

  async delete(ids) {
    const searchParams = new URLSearchParams()
    ids.forEach((id) => searchParams.append('ids[]', id))

    await fetch(`/secret?${searchParams.toString()}`, {
      method: 'DELETE',
    })
  },

  async getPresets() {
    return fetch('/secret-preset')
  },

  async getPresetPermissions(presetId) {
    return fetch(`/secret-preset/${presetId}`)
  },
}
