import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { toJS } from 'mobx'

import ContentWrapper from '../../components/ContentWrapper'
import ResourceTable from '../../components/ResourceTable'
import { Column } from '../../components/Table'
import Text from '../../components/Text'
import Icon from '../../components/Icon'

import t from '../../utils/translate'
import { useHistory } from 'react-router-dom'
import { createRedirectPath } from '../../utils'
import {
  ActionLayerStore,
  AuthStore,
  RoleManagementStore,
  UIStore,
  UserManagementStore,
} from '../../stores'
import PageTitle from './PageTitle'
import InstancesRoleColumn from './components/InstancesColumn'
import InstancesModal from './components/InstancesModal'
import ResendButton from './ResendButton'
import ChangedField from '../../components/ChangedField'

const UserManagementList = () => {
  const history = useHistory()
  const [selectedRows, setSelectedRows] = useState([])
  const [selectedEmail, selectEmail] = useState('')

  useEffect(() => {
    UserManagementStore.fetchUsers()
    RoleManagementStore.fetchRoles()
  }, [])

  const handleDelete = async () => {
    await UserManagementStore.delete(selectedRows)

    if (UserManagementStore.state !== 'error') {
      setSelectedRows([])
      return true
    }

    return false
  }

  const showDeleteWarning = () => {
    ActionLayerStore.openActionLayer({
      header: t('delete_modal_title')(selectedRows.length),
      saveTitle: 'OK',
      onSave: handleDelete,
      onClose: () => {},
    })
  }

  const handleRowClick = (row) =>
    history.push(createRedirectPath('/user/' + row.user_id))

  const filters = [
    {
      type: 'search',
      title: t('Search for email'),
      name: 'q',
      onChange: UserManagementStore.setFilter,
      defaultValue: UserManagementStore.filter?.q,
      info: t('Please input at least three characters to search.'),
    },
    {
      type: 'single-select',
      title: t('Role'),
      name: 'filterByRole',
      onChange: UserManagementStore.setFilter,
      options: [
        { label: 'All', value: null },
        ...RoleManagementStore.roles.map((role) => ({
          label: role.name
            .replace('Makaira.', '')
            .replace(`${toJS(RoleManagementStore.rolePrefix)}.`, ''),
          value: role.id,
        })),
      ],
      value: UserManagementStore.filter?.filterByRole,
    },
    {
      type: 'single-select',
      title: t('Instance'),
      name: 'filterByInstance',
      onChange: UserManagementStore.setFilter,
      options: [
        { label: 'All', value: null },
        ...UIStore.instances.map((instance) => ({
          label: instance,
          value: instance,
        })),
      ],
      value: UserManagementStore.filter?.filterByInstance,
    },
  ]

  const bulkActions = [
    {
      title: t('Delete'),
      onExecute: () => showDeleteWarning(),
    },
  ]

  const checkDisable = (row) =>
    AuthStore.user.email === row.email || row.is_external_sso === true

  return (
    <>
      <PageTitle />
      <ContentWrapper className="user-management">
        <ResourceTable
          rowKey="user_id"
          title={t('All Users')}
          store={UserManagementStore}
          resourceName="users"
          loading={UserManagementStore.state === 'pending'}
          onRowClick={handleRowClick}
          headerAction={{
            type: 'create',
            action: () => history.push(createRedirectPath('/user/new')),
            children: t('Invite User'),
          }}
          noResultsText={t('No user could be found.')}
          emptyText={t('User Management')}
          createActionText={t('Invite User')}
          createAction={() => history.push(createRedirectPath('/user/new'))}
          filters={filters}
          rowSelection={{
            checkDisable,
            selectedRows,
            onSelectionChange: (newRows) => setSelectedRows(newRows),
          }}
          bulkActions={bulkActions}
          hideEmptyComponentWhenEmpty={UserManagementStore.hideEmptyComponent}
        >
          <Column
            width={100}
            title={t('Email')}
            dataIndex="email"
            render={(value) => <Text weight="bold">{value}</Text>}
          />
          <Column
            width={600}
            title={t('Role')}
            dataIndex="instances"
            render={(instances = {}, record) => {
              return (
                <div>
                  <InstancesRoleColumn
                    instance={Object.keys(instances)[0]}
                    roles={instances[Object.keys(instances)[0]]}
                    truncate={Object.keys(instances).length > 1}
                    selectEmail={() => selectEmail(record.email)}
                  />
                </div>
              )
            }}
          />
          <Column
            width={330}
            dataIndex="new_user"
            render={(value, row) =>
              value && !row.last_login && <ResendButton row={row} />
            }
          />
          <Column
            title={t('Last Login')}
            dataIndex="last_login"
            sorter={true}
            align="right"
            width={200}
            render={(date) => (
              <Text>
                {date ? (
                  <ChangedField changed={{ date }} timeOnly />
                ) : (
                  t('Not logged in yet')
                )}
              </Text>
            )}
          />
          <Column
            width={20}
            render={() => <Icon symbol="angle-right" width={18} height={18} />}
          />
        </ResourceTable>
        <InstancesModal
          email={selectedEmail}
          closeModal={() => selectEmail('')}
          modalVisible={selectedEmail !== ''}
        />
      </ContentWrapper>
    </>
  )
}

export default observer(UserManagementList)
