import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import ProductPreview from '../../components/ProductPreview/ProductPreview'
import Text from '../../components/Text'
import { BannerStore, UIStore } from '../../stores'
import { debounce, t } from '../../utils'

const PreviewSection = () => {
  const handlePreviewChange = debounce(async (search) => {
    const currentLanguage =
      BannerStore.updatingBanner.language ||
      UIStore.enterpriseConfiguration.defaultLanguage

    BannerStore.fetchBannerPreviewList({
      currentLanguage,
      shopId: UIStore.shopId,
      page: search?.page || 1,
    })
  }, 500)

  useEffect(() => {
    handlePreviewChange()
    // eslint-disable-next-line
  }, [
    BannerStore.updatingBanner.identifier,
    BannerStore.updatingBanner.listType,
    BannerStore.updatingBanner.language,
    BannerStore.updatingBanner.showForAll,
    BannerStore.previewPageUrl,
  ])

  return (
    <div className="recommendations__edit-section">
      <Text size="echo" weight="bold">
        {t('Preview the order')}
      </Text>
      <ProductPreview
        tagsInputShown={true}
        type={
          BannerStore.updatingBanner.listType === 'search'
            ? 'product'
            : BannerStore.updatingBanner.listType
        }
        singleRow
        hideSearchPharseInput
        hideSelectDocument
        typeSelectShown={false}
        onChange={handlePreviewChange}
        products={BannerStore.getPreviewProductList(
          UIStore.enterpriseConfiguration
        )}
        loading={BannerStore.previewState === 'pending'}
        withoutSearchText={t('Select products to see the preview.')}
        headline=""
        currentPage={BannerStore.previewPage + 1}
        maxPage={BannerStore.previewMaxPage}
        maxProductsPerPage={BannerStore.previewProductsCount}
      />
    </div>
  )
}

export default observer(PreviewSection)
