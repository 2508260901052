import { makeAutoObservable } from 'mobx'
import { toast } from 'react-toastify'
import api from '../../api'
import { v4 as uuidv4 } from 'uuid'
import { downloadCSV } from '../../utils'

class RedirectStore {
  redirects = []
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
  }
  sorter = {
    field: 'changed',
    order: 'descend',
  }
  filter = {}
  state = 'pending' // "pending", "done" or "error"
  uploadingCSV = false

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  reset() {
    this.pagination = {
      current: 1,
      pageSize: 10,
      total: 0,
    }
  }

  setPagination(pagination) {
    this.pagination = pagination
    this.fetchRedirects()
  }

  setSorter(sorter) {
    this.reset()

    this.sorter = sorter
    this.fetchRedirects()
  }

  setFilter(filter) {
    this.reset()

    this.filter = filter
    this.fetchRedirects()
  }

  getById(id) {
    return this.redirects.find((s) => s.id == id)
  }

  *fetchRedirects() {
    try {
      const { data, total } = yield api.redirects.getAll({
        pagination: this.pagination,
        filter: this.filter,
        sorter: this.sorter,
      })

      this.redirects = data
      this.pagination.total = total
      this.state = 'done'
    } catch (error) {
      toast.error('Something went wrong loading the redirect listing.')
      this.state = 'error'
    }
  }

  *updateOrCreate(stream) {
    try {
      if (stream.state === 'edit') {
        yield api.redirects.update(stream)
        toast.success('Updated successfully')
      } else {
        const { data } = yield api.redirects.create(stream)
        this.updateRedirectState(stream, 'id', data.id)
        stream.id = data.id
        toast.success('Created successfully')
      }
      this.updateRedirectState(stream, 'state', '')
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong...')
    }
  }

  *delete(ids) {
    try {
      yield api.redirects.delete(ids)
      this.fetchRedirects()
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong...')
    }
  }

  updateRedirectState(data, key, value) {
    const redirectIndex = this.redirects.findIndex((r) => r.id === data.id)
    if (redirectIndex !== -1) {
      this.redirects[redirectIndex][key] = value
    }
  }

  addNewRedirect() {
    this.redirects.push({
      source: '',
      code: 301,
      target: '',
      state: 'create',
      id: uuidv4(),
      priority: 0,
    })
  }

  *createWithCSV(file) {
    this.uploadingCSV = true
    try {
      yield api.redirects.bulkCreateCSV(file)
      toast.success('Created successfully')
      this.fetchRedirects()
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong uploading csv...')
    } finally {
      this.uploadingCSV = false
    }
  }

  *createCSV() {
    try {
      const response = yield api.redirects.fetchCSVData()

      downloadCSV({ text: response.data, fileName: 'redirects.csv' })

      toast.success('Created successfully')
    } catch (e) {
      this.state = 'error'
      toast.error('Something went wrong downloading csv...')
    }
  }
}

export default new RedirectStore()
