import buildQueryString from '../utils/buildQueryString'
import { fetchUtil as fetch } from '../utils/fetchUtil'

export default {
  async get(id) {
    return fetch(`/synonym/${id}`)
  },

  async getAll(options) {
    const queryString = buildQueryString(options)

    const target = `/synonym?${queryString}`

    return fetch(target)
  },

  async create(synonym) {
    return fetch('/synonym', { method: 'POST', body: JSON.stringify(synonym) })
  },

  async update(synonym) {
    return fetch(`/synonym/${synonym.id}`, {
      method: 'PUT',
      body: JSON.stringify(synonym),
    })
  },

  async delete(ids) {
    const searchParams = new URLSearchParams()
    ids.forEach((id) => searchParams.append('ids[]', id))

    await fetch(`/synonym?${searchParams.toString()}`, {
      method: 'DELETE',
    })
  },
  async validate(language, synonyms) {
    return fetch('/synonym/validate', {
      method: 'POST',
      body: JSON.stringify({
        language,
        synonyms,
      }),
    })
  },
}
