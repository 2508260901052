import { observer } from 'mobx-react-lite'
import ReactDiffViewer from 'react-diff-viewer-continued'

import Modal from '../../components/Modal'

function DiffModal({ oldValue, newValue, ...props }) {
  return (
    <Modal {...props}>
      <ReactDiffViewer oldValue={oldValue} newValue={newValue} />
    </Modal>
  )
}

export default observer(DiffModal)
