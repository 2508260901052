import { useDraggable } from '@dnd-kit/core'
import classNames from 'classnames'
import Icon from '../../../components/Icon'
import Text from '../../../components/Text'
import { t } from '../../../utils'
import { AVAILABLE_FIELD_TYPES, SECTION_TYPE } from '../constants'

export const DragableElement = ({ element, id }) => {
  const newField = {
    uuid: id,
    id: element.value,
    label: element.value,
    type: element.value,
    parentId: 'none',
  }

  // default type of Array's items
  if (element.value === 'array') {
    newField.childConfig = {
      type: 'text',
    }
  } else if (element.value === 'documents') {
    // default type of Document
    newField.documentType = 'product'
  }

  if (element.value === 'conditional-section') {
    delete newField.id
    newField.showFields = true
  }

  if (element.value === 'number') {
    newField.defaultValue = 0
  }

  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id: id,
    data: {
      type: 'form-element',
      field: newField,
    },
  })

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      className={classNames({
        'component-editor__element': element.value !== 'conditional-section',
        'component-editor__section': element.value === 'conditional-section',
        'component-editor__element--dragging': isDragging,
      })}
    >
      {element.value === 'conditional-section' && (
        <div className="component-editor__section-inner">
          <Icon width="12px" height="12px" symbol={element.icon} />
          <Text>{t(element.label)}</Text>
        </div>
      )}
      {element.value !== 'conditional-section' && (
        <>
          <Icon width="12px" height="12px" symbol={element.icon} />
          <Text>{t(element.label)}</Text>
        </>
      )}
    </div>
  )
}

export const Element = ({ field }) => {
  const isSection = !field.type
  const element =
    AVAILABLE_FIELD_TYPES.find((elm) => elm.value === field.type) ||
    SECTION_TYPE

  return (
    <div
      style={{ maxWidth: 200 }}
      className={classNames('component-editor__element', {
        'component-editor__element--section': isSection,
      })}
    >
      <Icon width="12px" height="12px" symbol={element.icon} />
      <Text>{t(field.label || element.label)}</Text>
    </div>
  )
}
