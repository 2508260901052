export default function getDefaultPluginState(fieldConfig) {
  const { type } = fieldConfig

  switch (type) {
    case 'text':
      return getDefaultTextPluginState(fieldConfig)
    case 'richtext':
      return getDefaultRichTextPluginState(fieldConfig)
    case 'number':
      return getDefaultNumberPluginState(fieldConfig)
    case 'file':
      return getDefaultFilePluginState(fieldConfig)
    case 'checkbox':
      return getDefaultCheckboxPluginState(fieldConfig)
    case 'select':
      return getDefaultSelectPluginState(fieldConfig)
    case 'multiselect':
      return getDefaultMultiselectPluginState(fieldConfig)
    case 'object':
      return getDefaultObjectPluginState(fieldConfig)
    case 'array':
      return getDefaultArrayPluginState(fieldConfig)
    case 'documents':
      return getDefaultDocumentsPluginState(fieldConfig)
    case 'stream':
      return getDefaultStreamPluginState(fieldConfig)
    case 'hotspot':
      return getDefaultHotspotImagePluginState(fieldConfig)
    case 'colorpicker':
      return getDefaultColorPickerPluginState(fieldConfig)
    case 'date':
      return getDefaultDatePluginState(fieldConfig)
    default:
      return null
  }
}

function getDefaultTextPluginState(config) {
  const { id, defaultValue } = config

  let value = ''

  if (defaultValue) {
    value = defaultValue
  }

  return {
    [id]: value,
  }
}

function getDefaultRichTextPluginState(config) {
  const { id, defaultValue } = config

  let value = ''

  if (defaultValue) {
    value = defaultValue
  }

  return {
    [id]: value,
  }
}

function getDefaultNumberPluginState(config) {
  const { id, defaultValue } = config

  let value = ''

  if (defaultValue || defaultValue === 0) {
    value = defaultValue
  }

  return {
    [id]: value,
  }
}

function getDefaultFilePluginState(config) {
  const { id = 'id' } = config

  return {
    [id]: '',
  }
}

function getDefaultCheckboxPluginState(config) {
  const { id, defaultValue } = config

  let value = false

  if (defaultValue) {
    value = defaultValue
  }

  return {
    [id]: value,
  }
}

function getDefaultSelectPluginState(config) {
  const { id, options = [], defaultValue } = config

  let value = options[0] ? options[0].value : ''

  if (defaultValue) {
    value = defaultValue
  }

  return {
    [id]: value,
  }
}

function getDefaultMultiselectPluginState(config) {
  const { id, defaultValue } = config

  let value = []

  if (defaultValue) {
    value = defaultValue
  }

  return {
    [id]: value,
  }
}

function getDefaultObjectPluginState(config) {
  const { id, properties = [] } = config

  const defaultPropertyState = properties.reduce((acc, currentProperty) => {
    return {
      ...acc,
      ...getDefaultPluginState(currentProperty),
    }
  }, {})

  return {
    [id]: {
      ...defaultPropertyState,
    },
  }
}

function getDefaultArrayPluginState(config) {
  const { id } = config

  return {
    [id]: [],
  }
}

function getDefaultDocumentsPluginState(config) {
  const { id } = config

  return {
    [id]: {
      items: [],
      resolveDocuments: false,
    },
  }
}

function getDefaultStreamPluginState(config) {
  const { id } = config

  return {
    [id]: {
      resolveStream: true,
      streamId: '',
      maxDocumentCount: 8,
      sort: 'id',
      order: 'desc',
    },
  }
}

function getDefaultHotspotImagePluginState(config) {
  const { id } = config

  return {
    [id]: {
      image: '',
      spots: [],
    },
  }
}

function getDefaultColorPickerPluginState(config) {
  const { id } = config

  return {
    [id]: {
      hsla: 'hsla(229, 0, 0, 1.0)',
      rgba: 'rgba(0, 0, 0, 1.0)',
      hex: '#000000',
    },
  }
}

function getDefaultDatePluginState(config) {
  const { id, defaultValue } = config

  let value = ''

  if (defaultValue) {
    value = defaultValue
  }

  return {
    [id]: value,
  }
}
