import { makeAutoObservable } from 'mobx'
import { notification } from 'antd'
import { v4 as uuidv4 } from 'uuid'
import { debounce } from 'lodash'
import { toast } from 'react-toastify'

import api from '../../api'
import { Feed } from '../../models'
import StreamStore from '../StreamStore'
import UIStore from '../UIStore'
import { t } from '../../utils'

class FeedStore {
  feeds = []
  feedsList = []
  feedDetailOriginal = {}
  feedDetail = {}
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
  }
  sorter = {
    field: 'changed',
    order: 'desc',
  }
  filter = null
  state = 'pending' // "pending", "done" or "error"
  configurationPanelShown = false
  isDirty = false
  isUpdatingFeed = false
  hideEmptyComponent = false
  feedPreview = null
  fields = []
  fieldErrors = {}
  showHeaderAndFooter = true
  sampleProductEsIds = []
  previewType = 'product'
  debounceFetchFeedPreview = debounce((config) => {
    if (Object.keys(config).length === 0 || !config.stream) return
    return this.fetchFeedPreview(config)
  }, 300)

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  reset() {
    this.pagination = {
      current: 1,
      pageSize: 10,
      total: 0,
    }
  }

  setPagination(pagination) {
    this.pagination = pagination
    this.fetchFeeds()
  }

  setSorter(sorter) {
    this.reset()

    this.sorter = sorter
    this.fetchFeeds()
  }

  setFilter(filter) {
    this.reset()

    this.filter = { ...this.filter, ...filter }
    this.fetchFeeds()
  }

  getById(id) {
    return this.feeds.find((s) => s.id === id)
  }

  *fetchFeeds() {
    this.state = 'pending'

    try {
      const { data, total } = yield api.feeds.getAll({
        pagination: this.pagination,
        filter: this.filter,
        sorter: this.sorter,
      })

      // Store the data for the feeds list separated because antd design can't handle the key "children" in the table.
      // Store is separately because we also want the raw data for the form.
      this.feedsList = data.map(({ children, ...item }) => ({
        ...item,
        childrenData: children,
      }))

      this.feeds = data
      const isNotFiltered = !this.filter?.q && !this.filter?.type
      this.hideEmptyComponent = !isNotFiltered

      this.pagination.total = total
      this.state = 'done'
    } catch (error) {
      toast.error(t('Something went wrong loading the feed listing.'))
      this.state = 'error'
    }
  }

  *fetchFeedDetail(id) {
    this.state = 'pending'
    this.feedPreview = null
    this.sampleProductEsIds = []
    yield StreamStore.fetchAllStreams()
    this.configurationPanelShown = false
    if (id === 'new') {
      this.feedDetail = new Feed()
      this.feedDetail.stream = String(StreamStore.streams[0]?.id)
      this.configurationPanelShown = true
      this.state = 'done'
      return
    }
    try {
      const { data } = yield api.feeds.get(id)
      this.fetchFieldValidate(data)
      this.fetchFeedPreview(data)
      const selectedStream = StreamStore.streams.find(
        (stream) => stream.id == data.stream
      )
      this.fetchFields(selectedStream?.datatype)
      this.setPreviewType(selectedStream?.datatype)
      // the id of field is not unique, so we need to use uuidv4 to drag and drop working correctly
      data.fields = data.fields.map((field) => ({
        ...field,
        uniqueId: uuidv4(),
      }))
      this.feedDetail = data
      this.feedDetailOriginal = data

      this.state = 'done'
    } catch (error) {
      toast.error(t('Feed could not be loaded.'))
      this.state = 'error'
    }
  }

  *updateOrCreate(feed) {
    this.isUpdatingFeed = true

    try {
      if (feed.id) {
        yield api.feeds.update(feed)
      } else {
        const { data } = yield api.feeds.create(feed)
        return data.id
      }
      this.isDirty = false
      this.isUpdatingFeed = false
    } catch (error) {
      this.isUpdatingFeed = false
      this.state = 'error'
      toast.error('Something went wrong...')
    }
    this.fetchFeeds()
  }

  *delete(ids) {
    try {
      yield api.feeds.delete(ids)
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong...')
    }

    this.fetchFeeds()
  }

  *copyFeed(data) {
    try {
      yield api.feeds.copy(data)

      this.fetchFeeds()
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong...')
    }
  }

  *duplicate(ids) {
    try {
      yield api.feeds.duplicate(ids)
    } catch (error) {
      this.state = 'error'
      toast.error('Something went wrong...')
    }

    this.fetchFeeds()
  }

  *fetchFields(type) {
    try {
      const { data } = yield api.fields.getAggregationByType(type)
      this.fields = data
    } catch (error) {
      console.log(error)
      this.state = 'error'
      toast.error('Something went wrong fetching fields...')
    }
  }

  setIsConfigurationPanelShown(show) {
    this.configurationPanelShown = show
  }

  setDirty(dirty) {
    this.isDirty = dirty
  }

  setFeed(data, isSyncSettings) {
    if (isSyncSettings) {
      this.feedDetail = {
        ...this.feedDetail,
        ...data,
      }
    } else {
      this.feedDetail = data
    }

    this.isDirty = true
    this.debounceFetchFeedPreview(this.feedDetail)
  }

  updateFeedData(key, value) {
    this.feedDetail[key] = value
    this.isDirty = true
    this.debounceFetchFeedPreview(this.feedDetail)
  }

  updateFeedField(uniqueId, key, value) {
    const field = this.feedDetail.fields.find((f) => f.uniqueId === uniqueId)
    field[key] = value
    this.isDirty = true
    // 'title ' and 'id' set at sametime, so just ignore 'title'
    if (key !== 'title') {
      this.debounceFetchFeedPreview(this.feedDetail)
    }
  }

  removeField(uniqueId) {
    this.feedDetail.fields = this.feedDetail.fields.filter(
      (field) => field.uniqueId !== uniqueId
    )
    this.isDirty = true

    this.debounceFetchFeedPreview(this.feedDetail)
  }

  get fieldOptions() {
    return this.fields.map((field) => ({ value: field.id, label: field.title }))
  }

  *fetchFeedPreview(config) {
    config = config || {}

    const defaultLanguage = UIStore.enterpriseConfiguration.defaultLanguage
    try {
      const { data } = yield api.feeds.getFeedPreview(
        { ...this.feedDetail, ...config },
        defaultLanguage,
        this.sampleProductEsIds
      )

      delete this.fieldErrors.body
      delete this.fieldErrors.header
      delete this.fieldErrors.footer

      data.errors.forEach((error) => {
        if (error.field !== null) {
          this.fieldErrors[error.field] = error
        }
      })

      this.feedPreview = data
    } catch (error) {
      this.state = 'error'
      toast.error(t('Something went wrong fetching feed preview…'))
    }
  }

  *fetchFieldValidate(feed) {
    this.fieldErrors = {}
    const defaultLanguage = UIStore.enterpriseConfiguration.defaultLanguage
    try {
      const { data } = yield api.feeds.validateFields(feed, defaultLanguage)
      data.errors.forEach((error) => {
        if (error.index !== null) {
          const fieldUniqueId = this.feedDetail.fields[error.index].uniqueId
          this.fieldErrors[fieldUniqueId] = error
        }
      })

      if (data.errors[0]?.error === 'Empty Stream') {
        notification.error({
          message: t('The stream does not contain documents.'),
          duration: 7,
          placement: 'bottomRight',
        })
      }
    } catch (error) {
      this.state = 'error'
      toast.error(t('Something went wrong fetching field validate…'))
    }
  }

  setShowHeaderAndFooter(show) {
    this.showHeaderAndFooter = show
  }

  setSampleProductEsIds(esids) {
    this.sampleProductEsIds = esids
  }

  setPreviewType(type) {
    this.previewType = type
  }
}

export default new FeedStore()
