import { useFormikContext } from 'formik'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import AutoComplete from '../../../../components/AutoComplete'
import Radio from '../../../../components/Radio'
import Switch from '../../../../components/Switch'
import Text from '../../../../components/Text'
import { FeedStore, StreamStore } from '../../../../stores'
import { t } from '../../../../utils'

const FeedContent = () => {
  const formikProps = useFormikContext()
  const feed = FeedStore.feedDetail

  const selectedStream = StreamStore.streams.find(
    (stream) => stream.id == formikProps.values.stream
  )

  useEffect(() => {
    if (!selectedStream?.datatype) return
    FeedStore.fetchFields(selectedStream?.datatype)
    FeedStore.setPreviewType(selectedStream?.datatype)
  }, [selectedStream])

  const handleToggleParent = (value) => {
    FeedStore.updateFeedData('parents', value)
    if (!value) {
      FeedStore.updateFeedData('parentsSingleOnly', value)
    }
  }

  const handleExportProductVariants = (value) => {
    if (value == 0) {
      FeedStore.updateFeedData('innerStream', null)
    } else {
      FeedStore.updateFeedData(
        'innerStream',
        String(StreamStore.streams[0]?.id)
      )
    }
  }

  const handleSelectStream = (streamId) => {
    formikProps.setFieldValue('stream', streamId || '')
    const newStream = StreamStore.streams.find(
      (stream) => stream.id == streamId
    )
    if (newStream?.datatype !== selectedStream?.datatype) {
      formikProps.setFieldValue('parentsSingleOnly', false)
      formikProps.setFieldValue('children', false)
      formikProps.setFieldValue('parents', newStream?.datatype !== 'product')
    }
  }

  return (
    <div className="feed-setting__feed-content">
      <AutoComplete
        title={t('Choose the stream to populate the feed')}
        value={
          formikProps.values.stream !== null
            ? String(formikProps.values.stream)
            : null
        }
        defaultOptions={StreamStore.streams.map((stream) => ({
          value: String(stream.id),
          label: stream.title,
        }))}
        onSelect={handleSelectStream}
        error={formikProps.errors.stream}
      />
      {selectedStream?.datatype === 'product' && (
        <>
          <Text weight="medium" size="charlie">
            {t('Export options')}
          </Text>
          <Text className="feed-setting__switch-title">
            {t('Export products')}
          </Text>
          <div className="feed-setting__feed-content-row">
            <Switch
              type="horizontal"
              title={t('include in export')}
              checked={feed.parents}
              onChange={handleToggleParent}
            />
            {feed.parents && (
              <Switch
                type="horizontal"
                title={t('only export when no variants are available')}
                checked={feed.parentsSingleOnly}
                onChange={(value) => {
                  FeedStore.updateFeedData('parentsSingleOnly', value)
                }}
              />
            )}
          </div>
          <Text className="feed-setting__switch-title">
            {t('Export product variants')}
          </Text>
          <Switch
            type="horizontal"
            title={t('include in export')}
            checked={feed.children}
            onChange={(value) => {
              FeedStore.updateFeedData('children', value)
            }}
          />
          {feed.children && (
            <div className="feed-setting__radio-group">
              <Radio.Group
                onChange={handleExportProductVariants}
                value={feed.innerStream ? '1' : '0'}
              >
                <Radio
                  label={<Text size="bravo">{t('All variants')}</Text>}
                  value="0"
                />
                <Radio
                  // When the Label would be clickable  the AutoComplete Select Dropdown would
                  // not work correctly (closes directly after opening).
                  labelClickable={false}
                  wrapperClassName="feed-setting__inner-stream-select"
                  label={
                    <AutoComplete
                      value={
                        feed.innerStream !== null
                          ? String(feed.innerStream)
                          : null
                      }
                      defaultOptions={StreamStore.streams.map((stream) => ({
                        value: String(stream.id),
                        label: stream.title,
                      }))}
                      onSelect={(value) =>
                        FeedStore.updateFeedData('innerStream', value || '')
                      }
                      placeholder={t('Stream (Filter for variants)')}
                      description={t('innerStreamDescription')}
                    />
                  }
                  value="1"
                />
              </Radio.Group>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default observer(FeedContent)
