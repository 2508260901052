import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { ActionLayerStore, PersonasStore, UIStore } from '../../stores'
import Text from '../../components/Text'
import { Column } from '../../components/Table'
import { createRedirectPath, t } from '../../utils'
import ResourceTable from '../../components/ResourceTable'
import EditPersonasModal from './EditPersonasModal'
import { useHistory, useLocation } from 'react-router'
import Badge from '../../components/Badge'
import Icon from '../../components/Icon'
import UsageModal from './UsageModal'

const Personas = () => {
  const [loading, setLoading] = useState(true)
  const [editVisible, setEditVisible] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [usage, setUsage] = useState({})
  const history = useHistory()
  const location = useLocation()
  const id = location.search.split('=')[1]
  useEffect(() => {
    const init = async () => {
      UIStore.fetchAvailableLanguages()
      await PersonasStore.fetchPersonas()

      setLoading(false)
    }
    init()
  }, [])

  useEffect(() => {
    if (id && id !== PersonasStore.detail.id) {
      setEditVisible(true)
    }
  }, [id])

  const closeModal = () => {
    setEditVisible(false)
    history.replace(createRedirectPath(`/settings/personas`))
  }

  const showDeleteWarning = () => {
    ActionLayerStore.openActionLayer({
      header: t('delete_modal_title')(selectedRows.length),
      saveTitle: 'OK',
      onSave: handleDelete,
      onClose: () => {},
    })
  }

  const handleDelete = async () => {
    if (!selectedRows.length) return

    await PersonasStore.delete(selectedRows)

    if (PersonasStore.state !== 'error') {
      setSelectedRows([])
      return true
    }

    return false
  }

  const filters = [
    {
      type: 'search',
      title: t('Search'),
      name: 'q',
      onChange: PersonasStore.setFilter,
      defaultValue: PersonasStore.filter?.q,
    },
  ]

  return (
    <div>
      <ResourceTable
        store={PersonasStore}
        resourceName="personas"
        rowKey="id"
        loading={loading}
        filters={filters}
        title={t('Personas')}
        headerAction={{
          type: 'create',
          children: t('Add'),
          action: () => {
            PersonasStore.getById('new')
            setEditVisible(true)
          },
        }}
        onRowClick={(record) =>
          history.push(createRedirectPath(`/settings/personas?id=${record.id}`))
        }
        bulkActions={[
          {
            title: t('Delete'),
            onExecute: () => showDeleteWarning(),
          },
        ]}
        rowSelection={{
          selectedRows,
          onSelectionChange: (newRows) => setSelectedRows(newRows),
        }}
      >
        <Column
          title={t('ID')}
          render={(instance) => <Text>{instance}</Text>}
          dataIndex="identifier"
        />
        <Column
          title={t('Name')}
          render={(name) => <Text>{name[UIStore.currentLanguage]}</Text>}
          dataIndex="name"
        />
        <Column
          title={t('Description')}
          render={(description) => (
            <Text>{description[UIStore.currentLanguage]}</Text>
          )}
          dataIndex="description"
        />
        <Column
          title={t('Used in...')}
          dataIndex="listType"
          width={'40%'}
          render={(_, record) => {
            const banners = record.banners?.length > 0
            const placements = record.placements?.length > 0
            const isUsedByLandingpage = record.usedByLandingpage

            const BadgeIcon = ({ type, text, id }) => {
              return (
                <Badge
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    setUsage({ id, type })
                  }}
                  text={
                    <div className="usage-tag">
                      {text}
                      <Icon
                        symbol="circle-info__solid"
                        width={15}
                        height={15}
                      />
                    </div>
                  }
                />
              )
            }
            return (
              <div className="badges">
                {isUsedByLandingpage && (
                  <BadgeIcon
                    text={t('Landingpage')}
                    id={record.id}
                    type="landingpage"
                  />
                )}
                {banners && (
                  <BadgeIcon
                    text={t('Advertising Banner')}
                    id={record.id}
                    type="banners"
                  />
                )}
                {placements && (
                  <BadgeIcon
                    text={t('Advertising Space')}
                    id={record.id}
                    type="placements"
                  />
                )}
              </div>
            )
          }}
        />
      </ResourceTable>
      <EditPersonasModal
        id={id}
        visible={editVisible}
        closeModal={closeModal}
      />
      <UsageModal {...usage} onClose={() => setUsage({})} />
    </div>
  )
}

export default observer(Personas)
